<template>
  <div class="content">
    <div class="row" style="background: #928383 ;">
      <div class="text-center col-xl-8 col-sm-12">
        <HeadImage :image="main_image" title="Tulum Wedding Planners"  :color="clubColor" caption="We make it happen." align="text-center"></HeadImage>
        <div class="card text-center" style="display: inline-block;padding: 20px; ">

          <img
            v-for="(image, _idx) in images"
            v-lazy="image + '?fit=clamp&h=50&w=50'"
            height="50px"
            width="50px"
          />
          <br>
          <br>
          <p>Your <strong>dream</strong>, our <strong>expertise</strong>. <br>Let your beach wedding party shine</p>

          <hr>

        <p>
          Whether you dream of a <strong>beachside ceremony in Tulum</strong> or a <strong>lavish party celebration in Playa del Carmen</strong>, we can make it happen.
        </p>
        </div>
        <div class="card" >
          <p>Trust our <strong>local experience</strong> and make <strong>your dream wedding a reality, we are
            professionals on the logistics, organization and details from the smallest perfection to the biggest
            themes!</strong></p>
        </div>


          <div class="card"
                 style="padding: 20px"
                 :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"

            >
              <br/>
              <p>Let us help you create a magical wedding experience. <br>Get your free consultation today!</p>


            <br>


            <RsvpViaDialog
              phone-number="529841339662"
              :club-config="this.rsvpConfig"
              mailAddress="gaby+wedding@tulum.party"
              message="WEDDING_INFO:%20%0a-------%0a"
              smsQR="https://imgix.cosmicjs.com/6f772280-337a-11ef-a504-63e081e4680f-qrcode-wedding-info-sms.png"
              waQR="https://imgix.cosmicjs.com/ee7b8820-3378-11ef-a504-63e081e4680f-wa-wedding-info.png"
              legend="Your dream, our expertise. Let your beach wedding party shine"
              text="CONTACT US"
            ></RsvpViaDialog>
              <br>
              <br/>
              <small
              >We operate from 9AM - 11PM / Answer within 15 mins</small
              >
              <br>
          </div>

          <div class="card text-left" style="background-color: #928383;padding: 20px">
            <h4 class="section-header">
              <img v-lazy="serviceIcon+'?w=40&h=40'" height="40" width="40" alt="Our Services" class="centered">

              Our Services
            </h4>
            <HeadImage :image="images[2]" ></HeadImage>

            <ul>
              <li><strong>Venue Selection</strong>: We help you choose from <strong>stunning beaches, luxurious resorts,
                nature getaways and charming local spots in Tulum and Playa del Carmen.</strong></li>
              <li><strong>Vendor Coordination:</strong> We work with <strong>top-tier photographers, florists,
                caterers,</strong> and anyone else needed to bring your vision to life.
              </li>
              <li><strong>Design &amp; Decor:</strong> From elegant to bohemian, <strong>we design and decorate your
                wedding according to your preferred style and theme.</strong></li>
              <li><strong>Day-of Coordination</strong>: Our team will be on-site to manage the entire event, from the
                reception to the grand finale,<strong>&nbsp;allowing you to relax and enjoy your dream.</strong></li>
              <li><strong>Comprehensive Guest Services:&nbsp;</strong>We can handle your <strong>dinner and beach club
                reservations, tour arrangements, Airbnb bookings, transportation,</strong> and everything else you or your
                guests could need to make your wedding week amazing for all.
              </li>
            </ul>
          </div>
          <div class="card text-left" style="background-color: #928383;padding: 20px">
            <h4 class="section-header">
              <img v-lazy="serviceIcon+'?w=40&h=40'" height="40" width="40" alt="Our Services" class="centered">
               BENEFITS
            </h4>
            <HeadImage :image="images[1]" ></HeadImage>

            <ol>
              <li><strong>Free Wedding Planning Services:&nbsp;</strong>We believe that everyone deserves a beautiful
                wedding without having to handle the stress of planning, logistics and so on.
              </li>
              <li><strong>Local Expertise:&nbsp;</strong>As locals we have <strong>extensive knowledge and
                connections</strong> in the Riviera Maya. Our team has curated<strong>&nbsp;the <a href="/tulum-clubs">best venues</a>, vendors, and
                services in Tulum & Playa del Carmen,</strong>
              </li>
              <li><strong>Personalized Planning:&nbsp;</strong>Every couple is unique, and so should be their wedding. We
                offer <strong>personalized planning tailored to your vision, preferences, and budget.</strong>
              </li>
              <li><strong>Best Deals Guaranteed:&nbsp;</strong>Our local connections allow us to negotiate <strong>the
                best prices and packages for you.&nbsp;</strong>From venues and catering to entertainment and decor, we
                ensure <strong>you will get the most out of your money.</strong></li>
              <li><strong>Seamless Coordination:&nbsp;</strong>Our experienced wedding planners will handle all the
                details, so <strong>you can focus on enjoying your magical day.&nbsp;</strong>We <strong>will coordinate
                  with vendors, manage timelines, and oversee the setup</strong> to ensure everything runs smoothly.
              </li>
            </ol>



          </div>


          <div id="carrousel" style="margin-top: 30px"

               :style="{ backgroundImage: `linear-gradient(to bottom, ${this.clubColor}, #050404)`, }">

            <h3 class="text-left section-header">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px;margin-right: 10px;"
              />
              GALLERY
            </h3>
            <Carrousel folder="tulum-wedding-planning" :color="clubColor"></Carrousel>
          </div>

          <div style="margin-top: 35px"

          >
            <h3 class="text-left section-header">
              <i class="tim-icons icon-tv-2"></i>&nbsp;ACTION
            </h3>
            <hr
              style="height: 2px; margin-top: -10px"
              :style="{ backgroundColor: this.clubColor }"
            />

            <div style="padding:70.36% 0 0 0;position:relative;">
              <iframe src="https://player.vimeo.com/video/923488223?h=0e7f461909&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;"
                      title="A Glimpse "></iframe></div>
            <br>
            <p>Let us help you create a magical wedding experience in the beautiful settings of Tulum and Playa del Carmen! Get your free consultation today!</p>

          </div>

        <br/>

      </div>

      <div class="card col-4 col-xl-4 d-none d-xl-inline">
        <br>
        <h4 class="section-header" style="font-weight: bold">
          <i class="tim-icons icon-chat-33"></i> GET IN TOUCH
        </h4>
        <div class="row">
          <div class="col-sm-12 text-center">
            <p>
              <strong>Let us handle the details. </strong>
              Don't wait, contact us before your ideal date disappears!
            </p>

            <br>
            <RsvpViaDialog
              phone-number="529841339662"
              smsQR="https://imgix.cosmicjs.com/6f772280-337a-11ef-a504-63e081e4680f-qrcode-wedding-info-sms.png"
              waQR="https://imgix.cosmicjs.com/ee7b8820-3378-11ef-a504-63e081e4680f-wa-wedding-info.png"
              :club-config="this.rsvpConfig"
              mailAddress="gaby+wedding@tulum.party"
              message="WEDDING_INFO:%20%0a-------%0a"
              legend="Your dream, our expertise. Let your beach wedding party shine"
              text="INFO"
            ></RsvpViaDialog>
            <br>
            <hr>
          </div>
        </div>
        <br>

        <section :style="{ background: `linear-gradient(${this.clubColor}, black )` }">
          <img v-lazy="images[1]+'?w=259&h=259&fit=clamp'" alt="services" height="259" width="259">

          <h4 class="section-header">
            <img v-lazy="serviceIcon" height="30px" width="30px" alt="our services">
            SERVICES
          </h4>
          <ul class="list-unstyled" style="padding: 5px">
            <li>Venue Selection</li>
            <li>Vendor Coordination
            </li>
            <li>Design &amp; Decor</li>
            <li>Day-of Coordination</li>
            <li>Comprehensive Guest Services&nbsp;
            </li>
          </ul>
        </section>
        <section :style="{ background: `linear-gradient(${this.clubColor}, black )` }">
          <img v-lazy="images[0]+'?w=259&h=259&fit=clamp'" alt="services" height="259" width="259">

          <h4 class="section-header">
            <i class="tim-icons icon-key-25"></i>
             BENEFITS
          </h4>
          <ul class="list-unstyled" style="padding: 5px">
            <li>Local Experts</li>
            <li>Personalised
            </li>
            <li>Best Deal</li>
            <li>Seamless Coordination</li>
          </ul>
        </section>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Reservations from '../../components/Reservations/Reservations';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );

import Carrousel from '@/components/Carrousel';
import HeadImage from "@/components/HeadImage/HeadImage";

export default {
  name: 'WeddingPlanning',
  props: ['slug'],
  metaInfo() {

    return {
      title: `Wedding Planning`,
      meta: [
        {
          name: 'description',
          content: `Plan your dream wedding in Tulum, Mexico with Tulum Party! We specialize in creating unique and unforgettable experiences for your special day.`,
        },
        {
          name: 'keywords',
          content: `wedding planner, wedding planning tulum mexico, tulum destination wedding planner, riviera maya wedding planner, mexico wedding planner, tulum beach wedding, tulum wedding packages, tulum wedding ceremony, tulum wedding reception, luxury tulum wedding, bohemian tulum wedding, eco-friendly tulum wedding, tulum wedding photographer, tulum wedding videographer, tulum wedding vendors, tulum wedding planning on a budget, all-inclusive tulum wedding packages, intimate tulum wedding ceremony, unique tulum wedding venues, stress-free tulum destination wedding, tulum wedding with cenote ceremony, tulum wedding with Mayan influence, tulum wedding with live music, tulum wedding with fireworks, organizador de bodas tulum, planeación de bodas tulum mexico, boda destino tulum riviera maya, boda en la playa tulum, paquetes de boda tulum`
        },
        {
          property: 'og:title',
          content: `Wedding Planning`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/Wedding-planning`,
        },
        {
          property: 'og:description',
          content: `Wedding Planning .`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: this.main_image,
        },
      ],
    };
  },
  data: () => {
    return {
      PHONE_NUMBER: '529841339662',
      showAll: false,
      showDetailsTab: true,
      showFaqsTab: false,
      showLocationTab: false,
      showReviewTab: false,
      video: false,
      clubColor: '#c6aaaa',
      images: ['https://imgix.cosmicjs.com/0277e840-2759-11ef-adb1-8b946b3a80e4-wedding_planning_7.jpg',
        'https://imgix.cosmicjs.com/b2e7abe0-2757-11ef-adb1-8b946b3a80e4-wedding_planning_05.jpg',
        'https://imgix.cosmicjs.com/b2e58900-2757-11ef-adb1-8b946b3a80e4-wedding_planning_2.jpg'

      ],
      secondaryColor: '#c6aaaa',
    showPalms: false,
      clubStyle: '',
      clubConfig: {},
      logo: 'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',
      rsvpConfig: {name: 'Wedding Planners', logo: 'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',},
      clubTagsConfig: {},
      serviceIcon: 'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',
      main_image:
        'https://imgix.cosmicjs.com/b2e8e460-2757-11ef-adb1-8b946b3a80e4-wedding_planning_02.jpg',

    };
  },
  methods: {},
  components: {
    HeadImage,
    Carrousel,
    RsvpViaDialog,
    Reservations,
  },
  computed: mapState({
    incomingClubParties: (state) => {
      return state.incomingClubParties[state.venue.slug] || [];
    },
    club: (state) => state.venue,
    clubs: (state) => state.venues,
  }),
  created() {

  },
};
</script>
